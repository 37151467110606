.downloadButton {
  border-radius: 25px;
  border: 1px solid white;
  padding: 6px 22px;
  background-color: transparent;
  color: white;
  outline: none;
  font-size: 14px;
  transition: 0.4s;

  z-index: 999999999999999999;
  position: absolute;
  top: 0;
  right: 0;
}
.downloadButton:hover {
  color: #003e4f;
  background-color: #fff;

}
.barsIcon {
  color: #fff;
  font-size: 1.5rem;
}



/*navbar*/

.menuContainer label {
  position: relative;
  z-index: 12;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
}

.menuContainer input {
  display: none;
}

.menuContainer input:checked~label {
  right: 0;
}


.vh100 {
  height: 100vh;
}

.burger {
  position: relative;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 9999;
}

.burger g {
  stroke: #fff;
  transition: stroke 0.25s ease-in-out;
}

.burger g:hover {
  stroke: #ccc;
}

#test:checked~label .burger {
  opacity: 0;

}

.close {
  position: absolute;
  top: 0;
  right: 2px;
  cursor: pointer;
  opacity: 0;
  z-index: 9999;
}

.close g:hover {
  stroke: #ccc;
}

.close g {
  stroke: #fff;
  transition: stroke 0.25s ease-in-out;
}

.close line {
  transform-origin: 0%;
}

.close g {
  transform: rotateZ(45deg);
  transform-origin: 50%;
}

#test:checked~label .close {
  opacity: 1;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100vh;
  background-color: #003e4f;
  transition: opacity 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #C4C4C4;
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  left: 0;
  top: 0;
  
}

.overflow-hidden {
  overflow-y: hidden !important;
}

#test:checked~.menu {
  opacity: 1;
  z-index: 10;
}

.menu li {
  margin-top: 50px;
  transition: margin 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-bottom: 15px;
  list-style-type: none;
}

#test:checked~.menu li {
  margin-top: 0px;
  list-style-type: none;
}