.OrangeCol {
  color: #fa5551;
  font-weight: bold;
  font-size: 20px;
}
.bkgrclr {
  background-color: #fafafa;
}
.hotelFeature {
  width: 100%;
  display: flex;
  align-items: left;
}
.hotelFeature > span {
  background-color: #f2f5f8;
  padding: 8px;
  border-radius: 10px;
}
.roomDetails {
  margin-top: 20px;
}
.roomDetails > p > span {
  color: #fa5551;
}

.homeMenu{
  background-color: transparent;
    position: absolute;
    width: 100%;
}

.homeBg {
  background-image: url('../assets/imgs/home_left.jpg'), url('../assets/imgs/home_right.jpg');
  background-position: left center, right center;
  background-repeat: no-repeat, repeat;
}