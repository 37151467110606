.whyDoi {
  background-image: url(../../assets/imgs/why_doi.jpg);
  background-size: cover;
  background-position: center;
}

.whyDoi img {
  width: 135px;
}

.whyDoi h4 {
  font-weight: 600;
  color: #fff;
}

.whyDoi p {
  color: #fff;

}