.footer {
  background-color: #242929;
  padding-top: 5rem;
}
ul {
  list-style: none;
}
ul > li:first-child {
  color: #fa5551;
}
ul > li {
    margin-bottom: 20px;
}

ul > li > a {
  text-decoration: none;
  color: #cdcdcd;
}
ul > li > a:hover {
  color: #fff;
}
.copyright {
  margin-top: 3rem;
  background-color: #222222;
  color: #cdcdcd;
}
.copyright > p {
  margin: 0;
  font-size: 14px;
}
.socialPics {
    margin: 0;
    width: 27px;
}