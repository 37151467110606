.header {
  background-image: url('../../assets/imgs/header.png');
  background-size: cover;
  display: flex;
  border-radius: 0px 0px 130px 130px;
}

.header .circles {
  width: 240px;
  margin: 2rem 0;
}

.header p {
  color: #fff;
  margin: 0;
}

.header h3 {
  color: #fff;
}

.header input {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding-left: 1.5rem;
  outline: none;
  font-size: 14px;
}

.header i {
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 1.2rem;
  margin: auto;
}

.header a {
  background-color: #fa5551;
  padding: 8px 10px;
  border-radius: 25px;
  display: flex;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translate(0, -50%);
}