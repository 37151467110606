@font-face {
  font-family: "Fedra";
  src: url("./assets/fonts/Fedra.ttf");
}

@font-face {
  font-family: "FedraBold";
  src: url("./assets/fonts/FedraBold.otf");
}

* {
  font-family: "Fedra";
}

h2 {
  font-family: "FedraBold" !important;
}

.primaryBg {
  background-color: #003e4f;
}

.fedraB {
  font-family: "FedraBold" !important;
}

.showInMob {
  display: none;
}

.hideInMob {
  display: block;
}

@media (max-width: 767.98px) {
  .menuContainer {
    padding: 1.5rem !important;
  }

  .showInMob {
    display: block !important;
  }

  .hideInMob {
    display: none !important;
  }

  .footer ul {
    padding: 0 !important;
  }

  .footer ul li {
    text-align: center !important;
    margin-bottom: 15px !important;
  }

  .whyDoi img {
    width: 85px !important;
  }
  .footerLogo{
    width: 185px !important;
  }
  .container, .container-fluid {
    --bs-gutter-x: 2rem !important;
  }
  .downloadButton {
    position: relative;}
    .order2Mob{
      order: 2;
    }
}