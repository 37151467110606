.propertyImg {
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.propertyDesc {
  background-color: #fff;
  padding: 1rem !important;
  border-radius: 0px 0px 15px 15px;
}

.propertyDesc h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #fa5551;
  margin: 0;
}
.propertyDesc .detail {
  background-color: #f2f5f8;
  padding: 2px 10px;
  border-radius: 8px;
  margin: 0;
}
.propertyDesc .detail img {
  width: 23px;
  max-height: 18px;
}
.propertyDesc .detail span {
  color: grey;
  margin-left: 5px;
}
.areaDetail {
  color: #fa5551;
  margin: 0;
}
.areaDetail span {
  color: grey;
}
